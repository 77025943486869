import { Picture, Link } from '@components/ui'
import s from '../../Home.module.css'
import { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { refLink } from '../../utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Grid, Mousewheel } from 'swiper'
import 'swiper/css/grid'
import { NextArrow, PrevArrow } from '../Components/SwiperArrow'

const ImageRecommend = ({ recommend, title }) => (
  <Link
    href={refLink(recommend.href, title, '0')}
    className="hover:!no-underline"
  >
    <div className="relative px-6 text-center min-md:text-left">
      <Picture
        source={recommend.image}
        className="mx-auto w-[180px] min-md:w-[305px] min-xl:w-[420px]"
      />
      <p
        dangerouslySetInnerHTML={{ __html: recommend.title }}
        className="text-sm font-medium text-black min-l:absolute min-l:bottom-0 min-l:left-6"
      ></p>
    </div>
  </Link>
)

const ModelRecommend = ({ recommend, title }) => (
  <div className="relative min-l:h-full">
    <iframe
      src={recommend.model}
      className="h-[180px] w-full border-0 min-l:h-[calc(100%-60px)]"
    ></iframe>
    <div className="min-l:flex min-l:items-center min-l:justify-between min-l:px-6 min-l:py-4">
      <p
        dangerouslySetInnerHTML={{ __html: recommend.title }}
        className="text-center text-sm font-medium text-black min-l:text-left min-l:text-base"
      ></p>
      <div className="absolute left-4 top-0 flex items-center gap-1 rounded-full bg-[#f3f3f3] pl-1 pr-2 min-l:relative min-l:left-0">
        <Picture source={recommend.model_icon} className="w-6 min-l:w-10" />
        <p
          className="text-xs font-medium min-l:text-base"
          dangerouslySetInnerHTML={{ __html: recommend.model_text }}
        ></p>
      </div>
    </div>
  </div>
)

const Recommend = ({ recommend, title }) => (
  <div className="mb-6 shrink-0 min-l:mb-0 min-l:flex min-l:w-[448px] min-l:flex-col min-l:justify-center">
    {recommend.type === 'image' ? (
      <ImageRecommend recommend={recommend} title={title} />
    ) : (
      <ModelRecommend recommend={recommend} title={title} />
    )}
  </div>
)

const Tag = ({ tag }) => (
  <span className="z-[1] rounded-full bg-[#b3ecfb] px-[10px] py-1 text-xs font-semibold leading-[1.2] text-brand-color min-xl:text-sm">
    {tag}
  </span>
)

const Tags = ({ tags }) => (
  <div className="absolute left-0 top-0 flex  flex-wrap justify-center gap-1 min-l:left-10">
    {Array.isArray(tags) ? (
      tags.map((tag, index) => <Tag tag={tag} key={index} />)
    ) : (
      <Tag tag={tags} />
    )}
  </div>
)

const Card = ({ item, section, index }) => (
  <div
    className={cn(
      'relative box-border px-2 text-center min-l:px-10 min-l:py-[15px] min-xl:py-5',
      s.picBox
      // {
      //   '!pb-0': index >= section.lists.length / 2,
      // }
    )}
  >
    <Link
      className="hover:!no-underline"
      href={refLink(item.href, section.title, index + 1)}
    >
      {item.tag && <Tags tags={item.tag} />}
      <Picture
        source={item.image}
        className="flex justify-center"
        imgClassName="w-[129px]"
        alt={item.title}
        imgWidth={387}
        imgHeight={387}
      />
      <p
        className="mt-2 break-words text-sm font-medium leading-[1.2]"
        dangerouslySetInnerHTML={{ __html: item.title }}
      />
    </Link>
  </div>
)

/**
 * Renders a category section on the home page.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.section - The category section data.
 * @param {string} props.locale - The locale of the page.
 * @param {Object} props.shopCommon - The common shop data.
 * @param {Array} props.relatedProducts - The related products data.
 * @returns {JSX.Element} The rendered category section.
 */
const Category = ({ section }) => {
  const [len, setLen] = useState(section.limit || 6)
  const [isPad, setIsPad] = useState(false)
  const [isPc, setIsPc] = useState(false)
  const [start, setStart] = useState(true)
  const [end, setEnd] = useState(false)
  const [swiper, setSwiper] = useState()

  const handleProgress = (swiper) => {
    setStart(swiper.isBeginning)
    setEnd(swiper.isEnd)
  }

  const lists = useMemo(() => {
    let adjustedLen = len

    if (isPad) {
      let remainder = len % 4
      adjustedLen = len + (4 - remainder)
    }

    if (isPc) {
      return section.lists
    }

    return section.lists?.slice(0, adjustedLen)
  }, [isPad, isPc, len, section.lists])

  const toggle = () => {
    const listsLength = section.lists.length
    const limit = section.limit || 6
    setLen(len === listsLength ? limit : listsLength)
  }

  const settings = {
    modules: [Grid, Mousewheel],
    slidesPerView: 'auto',
    onProgress: handleProgress,
    slidesOffsetBefore: 16,
    // slidesOffsetAfter: 16,
    onSwiper: setSwiper,
    breakpoints: {
      1024: {
        slidesPerGroup: section.limit || 1,
        slidesOffsetBefore: -40,
        allowTouchMove: false,
        grid: {
          fill: 'row',
          rows: 2,
        },
      },
    },
    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true,
    },
  }

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)
    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  const resizeWindow = () => {
    const offsetWidth =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth
    setIsPad(false)
    setIsPc(false)
    if (offsetWidth >= 768 && offsetWidth < 1024) {
      setIsPad(true)
    }
    if (offsetWidth >= 1024) {
      setIsPc(true)
    }
  }

  return (
    <div className="layer overflow-hidden">
      <div className="content">
        <div className="py-5 min-l:py-10 min-xl:py-[60px]">
          <h2
            className={s.title}
            dangerouslySetInnerHTML={{ __html: section.title }}
          ></h2>
          <div className="relative mt-6 min-l:mt-8 min-xl:mt-12">
            <div className="overflow-hidden rounded-xl bg-white py-4 min-l:flex min-l:gap-10 min-l:pr-9">
              {section.recommend && (
                <Recommend
                  recommend={section.recommend}
                  title={section.title}
                />
              )}
              <Swiper {...settings} className="category-swiper !ml-0">
                {section.lists.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    className={cn('!h-auto !w-[96px] min-l:!w-[230px]')}
                  >
                    <Card
                      item={item}
                      section={section}
                      index={index}
                      key={index}
                    />
                  </SwiperSlide>
                ))}
                <PrevArrow
                  swiper={swiper}
                  start={start}
                  end={end}
                  title={section.title}
                />
                <NextArrow
                  swiper={swiper}
                  start={start}
                  end={end}
                  title={section.title}
                />
              </Swiper>
            </div>
            {/* <div className="mt-10 flex items-center justify-end gap-4">
              <PrevArrow />
              <NextArrow />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Category
